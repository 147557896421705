import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";

const Thumbnail = styled(GatsbyImage) <ThumbnailProps>`
  border-radius: 6px;
  width: 100%;
  height: auto;
  min-height: 15.3125rem;
  margin-bottom: 1.8125rem;
  max-height: 26rem;

  @media (min-width: 655px) {
    margin-right: ${props => (props.reverse ? "0px" : "1rem")};
    margin-left: ${props => (props.reverse ? "1.187rem" : "1rem")};
    margin-bottom: 3.312rem;
    min-height: 16.652rem;
    flex: 60%;
  }

  @media (min-width: 1201px) {
    margin-right: ${props => (props.reverse ? "0px" : "3.187rem")};
    margin-left: ${props => (props.reverse ? "1.187rem" : "0.9rem")};
    min-height: 24.812rem;
    flex: 40%;
  }
`;

const Content = styled.div`
  align-items: center;

  h1 {
    font-size: 1.875rem;
    line-height: 2.15rem;
    color: rgba(0,0,0,0.9);
  }

  @media (min-width: 655px) {
    display: flex;
    flex: 40%;
    justify-content: center;
  }
  @media (min-width: 1201px) {
    flex: 60%;

    h1 {
      font-size: 3.312rem;
      line-height: 4rem;
    }
  }
`;

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;

  @media (min-width: 655px) {
    justify-content: center;
    flex-direction: ${props => (props.reverse ? "row" : "row-reverse")};
  }
`;

type WrapperProps = {
  reverse?: boolean
}

type ThumbnailProps = {
  image: any,
  objectFit: string,
  reverse?: boolean,
  alt: string
}

type HeroProps = {
  featuredImage: {
    gatsbyImageData: IGatsbyImageData,
    description: string
  },
  children: any,
  reverse?: boolean
}

const Hero = ({ featuredImage, children, reverse = false }: HeroProps) => {
  const image = getImage(featuredImage.gatsbyImageData);

  return (
    <Wrapper reverse={reverse}>
      <Content>{children}</Content>
      <Thumbnail image={image} alt={featuredImage.description} objectFit="contain" />
    </Wrapper>
  );
}

export default Hero;

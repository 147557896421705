import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router"

import Facebook from "./Facebook";
import Twitter from "./Twitter";

import defaultSocialThumbnail from "../../images/social-media-thumbnail/default-flat.png";
import logo from '../../images/landing_logo_gpm.svg'

type SEOProps = {
  title?: string,
  desc?: string,
  image?: string
}

const SEO = ({ title, desc, image = defaultSocialThumbnail }: SEOProps) => {
  const { site } = useStaticQuery(query);
  const { pathname } = useLocation()
  const contentfulCDN = "images.ctfassets.net";

  const {
    buildTime,
    siteMetadata: {
      siteUrl,
      siteName,
      defaultDescription,
      siteLanguage,
      ogLanguage,
    },
  } = site;

  const seo = {
    title: title || siteName,
    description: desc || defaultDescription,
    image: image.includes(contentfulCDN) ? `https:${image}` : `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ""}`,
    logo: logo,
  };

  const corporationSchema = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    "@id": siteUrl,
    "name": "Global Precious Metals",
    "legalName": "Global Precious Metals Pte Ltd",
    "url": siteUrl,
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "3 Shenton Way #19-10 Shenton House",
      "addressLocality": "Singapore",
      "postalCode": "068805",
      "addressCountry": "SG"
    },
    "logo": seo.logo,
    "contactPoint": [{
      "@type": "ContactPoint",
      "telephone": "+6562249198",
      "contactType": "sales",
      "areaServed": "SG",
      "availableLanguage": "en"
    }, {
      "@type": "ContactPoint",
      "telephone": "+41223202017",
      "contactType": "sales",
      "areaServed": "CH",
      "availableLanguage": ["fr", "en", "German"]
    }],
    "sameAs": "https://www.linkedin.com/company/global-precious-metals/"
  };

  // This is reserved for when there is search functionality

  // const webSchema = {
  //   "@context": "https://schema.org/",
  //   "@type": "WebSite",
  //   "name": "Name",
  //   "url": "url",
  //   "potentialAction": {
  //     "@type": "SearchAction",
  //     "target": "https://example.com/search?q={search_term_string}",
  //     "query-input": "required name=search_term_string"
  //   }
  // };

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <script type="application/ld+json">
          {JSON.stringify(corporationSchema)}
        </script>
      </Helmet>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type="website"
        url={seo.url}
        locale={ogLanguage}
      />
      <Twitter title={seo.title} image={seo.image} desc={seo.description} />
    </>
  );
};

export default SEO;

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        siteName: title
        defaultDescription: description
        siteLanguage
        ogLanguage
      }
    }
  }
`;

import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Row, Col } from "antd";
import { Link } from "gatsby";
import li from "../images/li.svg";
import styled from "styled-components";

type GPMOffice = {
  name: string,
  address: {
    address: string
  }
}

const FooterWrapper = styled.footer`
  color: #D7C596;
  padding-top: 2.5rem; 
  padding-bottom: 2.5rem;
`;

const SocialIconsWrapper = styled(Col)`
  margin-top: 0.7rem;
  
  @media (min-width: 636px) {
    margin-top: 0;
  }
`;

const FooterLegalWrapper = styled(Row)`
  flex-direction: column-reverse;

  div {
    text-align: center;
    margin-top: 1.5rem;
  }
  span {
    margin: 0 0.5rem;
  }

  @media (min-width: 636px) {
    flex-direction: row;

    div {
      text-align: left;
    }

    div:last-child {
      text-align: end;
    }
  }
`

const Footer = () => {
  const { allContentfulGpmOffice } = useStaticQuery(query);
  const companyOffices = allContentfulGpmOffice.nodes;

  return (
    <FooterWrapper className="footer_bar">
      <div className="header-home">
        <Row className="footer_bar_container" align="top" justify="space-between" gutter={10}>
          <Col md={18}>
            Global Precious Metals Pte Ltd (company No. 201228026H) is{" "}
            <span style={{ wordSpacing: -3 }}>r egistered</span> with the Ministry
          of Law in Singapore under the Precious Stones and Precious Metals Act.
          </Col>
          <SocialIconsWrapper span={4}>
            <a
              href="https://www.linkedin.com/company/global-precious-metals/"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="LinkedIn"
              title="LinkedIn"
            >
              <img src={li} alt="" role="presentation" />
            </a>
          </SocialIconsWrapper>
          <Col span={24}>
            <Row gutter={40} style={{ paddingTop: 40 }}>
              {
                companyOffices.map(({ name, address }: GPMOffice, i: number) => {
                  return <Col key={name} xs={12} md={6} style={{ marginBottom: "3rem" }}>
                    <strong>{name}</strong>
                    <br />-<br />
                    <div style={{ whiteSpace: "pre" }} dangerouslySetInnerHTML={{
                      __html: address.address,
                    }} />
                  </Col>
                })
              }
            </Row>
            <FooterLegalWrapper justify="space-between" gutter={{ xs: 12 }}>
              <Col xs={24} sm={12} >
                © 2020 GPM. All rights reserved.
              </Col>
              <Col xs={24} sm={12}>
                <Link to="/privacy-policy/">Privacy Policy</Link>
                <span>|</span>
                <Link to="/terms-of-service/"> Terms & Conditions</Link>
              </Col>
            </FooterLegalWrapper>
            <br />
          </Col>
        </Row>
      </div>
    </FooterWrapper >
  )
};

export default Footer;

const query = graphql`
  query allContentfulGpmOffice {
    allContentfulGpmOffice(sort: {fields: createdAt}) {
        nodes {
          name
          address {
            address
          }
        }
      }
    }
`;
